"use strict";

angular.module("informaApp")
    .service("DiseaseService", ['BaseApiService', "ConstantsSvc", function (BaseApiService, ConstantsSvc) {
        return {
            getDiseases: function (filter) {
                return BaseApiService.post('diseases', filter)
                .then(function (res) {
                    return res.data.data;
                });
                /*
                return $http.post(ConstantsSvc.API.URL + "diseases", filter).then(function (res) {
                    return res.data.data;
                });
                */
            },
            getDiseaseHierarchy: function () {
                return BaseApiService.get('disease-hierarchy')
                .then(function (res) {
                    return res.data.data;
                });
                /*return $http.get(ConstantsSvc.API.URL + "disease-hierarchy").then(function (res) {
                    return res.data.data;
                });
                */
            }
        };
    }]);